import {
  INPUT_BIRTHDATE_INFORMATION,
  INPUT_BIRTHDATE_SEARCHING,
  INPUT_COUNTRY_INFORMATION,
  INPUT_COUNTRY_SEARCHING,
  INPUT_DOCUMENT_NUMBER_INFORMATION,
  INPUT_DOCUMENT_TYPE_INFORMATION,
  INPUT_EMAIL_INFORMATION,
  INPUT_EMAIL_SEARCHING,
  INPUT_PHONE_NUMBER_INFORMATION,
  INPUT_PHONE_NUMBER_SEARCHING,
  PassengerTypeEnum,
  InputConfig
} from '@seco/insurance';

export const MOCKED_CUSTOMER_INPUT_CONFIG: { [configname: string]: InputConfig[] } = {
  ALL_FIELDS_EXCEPT_COUNTRY_FOR_POLICYHOLDER: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  ALL_FIELDS_EXCEPT_COUNTRY_FOR_ADULT: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  ALL_FIELDS_EXCEPT_COUNTRY_FOR_CHILD: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  ALL_FIELDS_EXCEPT_COUNTRY_FOR_INFANT: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  ONLY_BIRTHDATE_FOR_ADULT: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  ONLY_BIRTHDATE_FOR_CHILD: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  ONLY_BIRTHDATE_FOR_INFANT: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  NO_SPECIAL_FIELDS_POLICYHOLDER: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  NO_SPECIAL_FIELDS_ADULT: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  NO_SPECIAL_FIELDS_CHILD: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  NO_SPECIAL_FIELDS_INFANT: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  DISABLED_SPECIAL_FIELDS: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  NOT_EDITABLE_SPECIAL_FIELDS: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  NO_MANDATORY_SPECIAL_FIELDS: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  ALL_FIELDS_PLUS_SEARCH: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  ONLY_EMAIL_AND_PHONE_NUMBER_FOR_POLICYHOLDER: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  // On hold: Till further clarification, we need to avoid addition of country as specific input field
  ONLY_EMAIL_AND_COUNTRY_FOR_POLICYHOLDER: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    }
  ],
  ONLY_EMAIL_FOR_POLICYHOLDER: [
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  AIR_INDIA_CONFIG_FOR_POLICYHOLDER: [
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_DOCUMENT_TYPE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_DOCUMENT_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: true,
      enabled: true
    }
  ],
  AIR_INDIA_CONFIG_FOR_ADULT: [
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_SEARCHING,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_DOCUMENT_TYPE_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_DOCUMENT_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: true,
      enabled: true
    }
  ],
  AIR_INDIA_CONFIG_FOR_CHILD: [
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_SEARCHING,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_DOCUMENT_TYPE_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_DOCUMENT_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: true,
      enabled: true
    }
  ],
  AIR_INDIA_CONFIG_FOR_INFANT: [
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.INF,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_COUNTRY_SEARCHING,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_DOCUMENT_TYPE_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_DOCUMENT_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: true,
      enabled: true
    }
  ],
  VIETNAM_CONFIG_FOR_POLICYHOLDER: [
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_SEARCHING,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.POLICYHOLDER,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  VIETNAM_CONFIG_FOR_ADULT: [
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.ADT,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  VIETNAM_CONFIG_FOR_CHILD: [
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.CHD,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ],
  VIETNAM_CONFIG_FOR_INFANT: [
    {
      inputField: INPUT_BIRTHDATE_SEARCHING,
      passengerType: PassengerTypeEnum.INF,
      mandatory: true,
      editable: false,
      enabled: true
    },
    {
      inputField: INPUT_BIRTHDATE_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: true,
      editable: true,
      enabled: true
    },
    {
      inputField: INPUT_EMAIL_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_PHONE_NUMBER_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    },
    {
      inputField: INPUT_COUNTRY_INFORMATION,
      passengerType: PassengerTypeEnum.INF,
      mandatory: false,
      editable: false,
      enabled: false
    }
  ]
};
